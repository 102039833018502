<script>
import { MoreHorizontalIcon } from "@zhuowenli/vue-feather-icons";
// import Multiselect from "@vueform/multiselect";
// import "@vueform/multiselect/themes/default.css";

import FieldsServices from "../../../services/FieldsServices";
import Swal from "sweetalert2";

export default {
  props: ["currentField"],
  data() {
    return {
      currentPricing: null,
      fields: {
        name: 1,
        bundle: "",
        months: 0,
        m2_price: 0,
      },
      isCreateModalOpen: false,
      isUpdateModalOpen: false,
    };
  },
  components: {
    // Multiselect,
    MoreHorizontalIcon,
  },
  emits: ["getFieldVisits"],
  methods: {
    handleOpenCreate() {
      this.isCreateModalOpen = true;
    },
    handleOpenUpdate(pricing) {
      this.isUpdateModalOpen = true;
      this.currentPricing = pricing;
      this.fields.name = pricing.name;
      this.fields.bundle = pricing.bundle;
      this.fields.m2_price = pricing.m2_price;
      this.fields.months = pricing.months;
    },
    async handleCreateFieldVisit() {
      FieldsServices.createPricing(this.fields).then((res) => {
        console.log(res);
        if (!res.error) {
          Swal.fire({
            title: "Confirmation",
            text: "Visite créée avec succès",
            icon: "success",
          });

          this.fields = {
            name: 1,
            bundle: "",
            months: 0,
            m2_price: 0,
          };
        }

        this.$emit("getFieldVisits");
      });
    },
    async handleUpdatePricing() {
      FieldsServices.updatePricing(this.currentPricing.id, {
        ...this.fields,
      }).then((res) => {
        console.log(res);
        if (!res.error) {
          Swal.fire({
            title: "Confirmation",
            text: "Visite mise à jour avec succès",
            icon: "success",
          });
        }

        this.$emit("getFieldVisits");
      });
    },
  },
  mounted() {},
};
</script>

<template>
  <b-modal
    v-model="isUpdateModalOpen"
    hide-footer
    title="Modifier un pack"
    class="v-modal-custom"
    header-class="bg-light p-3"
  >
    <form action="#" id="addform">
      <div class="mb-3">
        <label for="amount" class="form-label">Nom du pack</label>
        <input
          type="text"
          id="name"
          class="form-control"
          placeholder="Entrer le nom du pack"
          required
          v-model="fields.name"
        />
      </div>

      <div class="mb-3">
        <label for="amount" class="form-label">Nombre de mois</label>
        <input
          type="number"
          id="months"
          class="form-control"
          placeholder="Entrer le nombre de mois"
          required
          v-model="fields.months"
        />
      </div>

      <div class="mb-3">
        <label for="amount" class="form-label">Prix du m<sup>2</sup></label>
        <input
          type="number"
          id="m2_price"
          class="form-control"
          placeholder="Entrer le prix"
          required
          v-model="fields.m2_price"
        />
      </div>

      <div class="mb-3">
        <label for="amount" class="form-label">Code du pack</label>
        <input
          type="text"
          id="bundle"
          class="form-control"
          placeholder="Entrer le code du pack"
          required
          v-model="fields.bundle"
        />
      </div>
      <div class="modal-footer v-modal-footer">
        <div class="hstack gap-2 justify-content-end">
          <button
            type="button"
            id="closemodal"
            class="btn btn-light"
            data-bs-dismiss="modal"
          >
            Fermer
          </button>
          <button
            type="button"
            class="btn btn-success"
            @click="handleUpdatePricing"
          >
            Enregistrer le pack
          </button>
        </div>
      </div>
    </form>
  </b-modal>

  <div class="row g-4 mb-3">
    <div class="col-sm-auto">
      <!-- <div>
        <button class="btn btn-success" @click="handleOpenCreate">
          <i class="ri-add-line align-bottom me-1"></i> Nouvelle visite
        </button>
      </div> -->
    </div>
    <div class="col-sm">
      <div class="d-flex justify-content-sm-end gap-2">
        <div class="search-box ms-2">
          <input type="text" class="form-control" placeholder="Rechercher..." />
          <i class="ri-search-line search-icon"></i>
        </div>

        <!-- <Multiselect
            class="multiselect form-control w-lg w-auto m-0"
            v-model="value"
            :close-on-select="true"
            :searchable="true"
            :create-option="true"
            :options="[
              { value: 'Status', label: 'Status' },
              { value: 'Active', label: 'Active' },
              { value: 'Block', label: 'Block' },
            ]"
          /> -->
      </div>
    </div>
  </div>

  <div class="row">
    <div
      class="col-xxl-3 col-sm-6 project-card"
      v-for="(item, index) of currentField.pricings"
      :key="index"
    >
      <div class="card card-height-100">
        <div class="card-body">
          <div class="d-flex flex-column h-100">
            <div class="d-flex">
              <div class="flex-grow-1">
                <p class="text-muted mb-4">{{ item.months }} mois</p>
              </div>
              <div class="flex-shrink-0">
                <div class="d-flex gap-1 align-items-center">
                  <div class="dropdown">
                    <button
                      class="btn btn-link text-muted p-1 mt-n2 py-0 text-decoration-none fs-15 shadow-none"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="true"
                    >
                      <MoreHorizontalIcon class="icon-sm"></MoreHorizontalIcon>
                    </button>

                    <div class="dropdown-menu dropdown-menu-end">
                      <!-- <router-link
                        class="dropdown-item"
                        :to="{
                          name: 'field-visits.details',
                          params: { id: item.id },
                        }"
                        ><i
                          class="ri-eye-fill align-bottom me-2 text-muted"
                        ></i>
                        Voir les détails
                      </router-link> -->
                      <a
                        class="dropdown-item"
                        href="javascript:void(0)"
                        @click="handleOpenUpdate(item)"
                      >
                        <i
                          class="ri-pencil-fill align-bottom me-2 text-muted"
                        ></i>
                        Modifier
                      </a>
                      <div class="dropdown-divider"></div>
                      <!-- <a
                        class="dropdown-item"
                        href="javascript:void(0)"
                        @click.prevent="handleDeleteFieldVisit(item)"
                      >
                        <i
                          class="ri-delete-bin-fill align-bottom me-2 text-muted"
                        ></i>
                        Supprimer
                      </a> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex mb-2">
              <div class="flex-shrink-0 me-3">
                <div class="avatar-sm">
                  <span
                    class="avatar-title rounded p-2"
                    :style="'background-color: ' + item.color"
                  >
                    <!-- <img
                      :src="'/assets/icons/' + item.trip_mode + '.png'"
                      alt=""
                      class="img-fluid p-0"
                    /> -->
                  </span>
                </div>
              </div>
              <div class="flex-grow-1">
                <h5 class="mb-1 fs-15">
                  <a href="#" class="text-dark">
                    {{ item.name }}
                  </a>
                </h5>
                <p class="text-muted text-truncate-two-lines mb-3">
                  {{ $formatPrice(item.m2_price) }} / m<sup>2</sup>
                </p>
              </div>
            </div>
            <div class="mt-auto">
              <div class="d-flex mb-2">
                <div class="flex-grow-1">
                  <div>Souscriptions</div>
                </div>
                <div class="flex-shrink-0">
                  <div>
                    <i class="ri-list-check align-bottom me-1 text-muted"></i>
                    {{ String(item.subscriptions.length).padStart(2, "0") }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end card body -->
        <div class="card-footer bg-transparent border-top-dashed py-2">
          <div class="d-flex align-items-center">
            <div class="flex-shrink-0">
              <div class="text-muted">
                <i class="ri-calendar-event-fill me-1 align-bottom"></i>
                Ajouté le {{ new Date(item.created_at).toLocaleString() }}
              </div>
            </div>
          </div>
        </div>
        <!-- end card footer -->
      </div>
      <!-- end card -->
    </div>
    <!-- end col -->
  </div>
  <!-- end row -->

  <!-- <div class="row g-0 text-center text-sm-start align-items-center mb-4">
      <div class="col-sm-6">
        <div>
          <p class="mb-sm-0 text-muted">
            Showing <span class="fw-semibold">1</span> to
            <span class="fw-semibold">10</span> of
            <span class="fw-semibold text-decoration-underline">12</span>
            entries
          </p>
        </div>
      </div>
      <div class="col-sm-6">
        <ul
          class="pagination pagination-separated justify-content-center justify-content-sm-end mb-sm-0"
        >
          <li class="page-item disabled">
            <a href="#" class="page-link">Previous</a>
          </li>
          <li class="page-item active">
            <a href="#" class="page-link">1</a>
          </li>
          <li class="page-item">
            <a href="#" class="page-link">2</a>
          </li>
          <li class="page-item">
            <a href="#" class="page-link">3</a>
          </li>
          <li class="page-item">
            <a href="#" class="page-link">4</a>
          </li>
          <li class="page-item">
            <a href="#" class="page-link">5</a>
          </li>
          <li class="page-item">
            <a href="#" class="page-link">Next</a>
          </li>
        </ul>
      </div>
    </div> -->
  <!-- end row -->
</template>
