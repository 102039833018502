<script setup>
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import Swal from "sweetalert2";
import Axios from "axios";

import {
  computed,
  ref,
  reactive,
  onMounted,
  onBeforeUnmount,
  defineProps,
} from "vue";
import store from "@/state/store";
import FieldsServices from "@/services/FieldsServices";

const selectedUser = ref(null);

const props = defineProps({
  field: {
    type: Object,
    required: true,
  },
});

const screenSize = reactive({
  width: window.innerWidth,
  height: window.innerHeight,
});

const onWindowResize = () => {
  const { innerWidth, innerHeight } = window;
  screenSize.width = innerWidth;
  screenSize.height = innerHeight;
};

const currentField = computed(() => props.field || {});

const selectedLots = ref([]);

const confirmAndSave = () => {
  Swal.fire({
    title: "Êtes-vous sûr de vouloir enregistrer ?",
    icon: "warning",
    showCancelButton: true,
    confirmButtonText: "Oui",
    cancelButtonText: "Annuler",
  }).then(async (result) => {
    if (result.isConfirmed) {
      store.dispatch("setGlobalLoading", true);
      await FieldsServices.updateLandOwner(
        selectedLots.value[0].id,
        props.field.id,
        { user_id: selectedUser.value }
      )
        .then(() => {
          window.location.reload();
        })
        .catch(() => {
          Swal.fire({
            text: "Erreur lors de la mise à jour du propriétaire",
            icon: "error",
          });
        });

      store.dispatch("setGlobalLoading", false);
    }
  });
};

const handleCanvasEvent = () => {
  selectedLots.value =
    window?.appCanvas?.allFields.filter((item) => item.selected) ?? [];
};

onMounted(() => {
  console.log(props);
  window.addEventListener("resize", onWindowResize);

  document.addEventListener("canvasFieldsEvent", handleCanvasEvent.bind(this));

  console.log("on entre en jeu");

  const intervalle = setInterval(() => {
    if (window.appCanvas) {
      console.log("Okay canvas");
      window.appCanvas.allFields = [...currentField.value.lands];
      clearInterval(intervalle);
    }
  }, 2000);

  // setTimeout(() => {}, 5000)
});

onBeforeUnmount(() => {
  document.removeEventListener(
    "canvasFieldsEvent",
    handleCanvasEvent.bind(this)
  );
});

const customerList = ref([]);

const getusers = async () => {
  await Axios.get("/admin/customers")
    .then((data) => {
      customerList.value = [];
      data.data.data.forEach((row) => {
        const monthNames = [
          "Jan",
          "Fev",
          "Mar",
          "Avr",
          "Mai",
          "Juin",
          "Juil",
          "Aou",
          "Sept",
          "Oct",
          "Nov",
          "Dec",
        ];
        var dd = new Date(row.date);
        row.date =
          dd.getDate() +
          " " +
          monthNames[dd.getMonth()] +
          ", " +
          dd.getFullYear();
        customerList.value.push(row);
      });
    })
    .catch((er) => {
      console.log(er);
    });
};

onMounted(() => {
  getusers();
});

const listUsers = computed(() => {
  return customerList.value.map((item) => ({
    value: item._id,
    label: item.customer,
  }));
});
</script>

<template>
  <div>
    <div>
      <!-- Our vision -->
      <section class="row">
        <div class="col-lg-7">
          <!-- <img src="/assets/images/our-sites/sitemap.jpg" /> -->

          <div
            class="canvas-container p-2 border border-primary rounded-lg relative w-full"
          >
            <canvas
              id="myCanvas"
              :width="
                screenSize.width > 1000
                  ? `${screenSize.width / 2.3}`
                  : `${screenSize.width}`
              "
              height="600"
            ></canvas>

            <div class="controls">
              <button id="zoomInBtn">
                <i class="fas fa-search-plus"></i> Zoom In
              </button>
              <button id="zoomOutBtn">
                <i class="fas fa-search-minus"></i> Zoom Out
              </button>
              <button id="moveLeftBtn">
                <i class="fas fa-arrow-left"></i> Left
              </button>
              <button id="moveUpBtn"><i class="fas fa-arrow-up"></i> Up</button>
              <button id="moveDownBtn">
                <i class="fas fa-arrow-down"></i> Down
              </button>
              <button id="moveRightBtn">
                <i class="fas fa-arrow-right"></i> Right
              </button>
            </div>
          </div>
        </div>
        <div
          v-if="selectedLots.length"
          class="col-lg-5"
          style="padding-top: 20px"
        >
          <div v-if="selectedLots[0].owner">
            <!-- {{ selectedLots }} -->
            <div class="table-responsive table-card mb-1">
              <table class="table align-middle table-nowrap mb-0">
                <thead class="text-muted table-light">
                  <tr>
                    <th colspan="2">Informations sur le lot</th>
                  </tr>
                </thead>
                <tbody id="file-list">
                  <tr>
                    <td>Propriétaire</td>
                    <td>{{ selectedLots[0].owner.username }}</td>
                  </tr>
                  <tr>
                    <td>Superficie</td>
                    <td>{{ selectedLots[0].area }}m<sup>2</sup></td>
                  </tr>
                </tbody>
                <template v-if="selectedLots[0].subscription">
                  <thead class="text-muted table-light">
                    <tr>
                      <th colspan="2">Information sur la ccription</th>
                    </tr>
                  </thead>
                  <tbody id="file-list">
                    <tr>
                      <td>Souscrit le</td>
                      <td>
                        {{
                          new Date(
                            selectedLots[0].subscription.from_date
                          ).toLocaleString()
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td>Code</td>
                      <td>{{ selectedLots[0].subscription.code }}</td>
                    </tr>
                    <tr>
                      <td>Montant total à payer</td>
                      <td>
                        {{
                          $formatPrice(
                            selectedLots[0].subscription.total_amount
                          )
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td>Montant versé</td>
                      <td>
                        {{
                          $formatPrice(
                            selectedLots[0].subscription.current_balance
                          )
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td>Reste à verser</td>
                      <td>
                        {{
                          $formatPrice(
                            selectedLots[0].subscription.remaining_balance
                          )
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td>Mensualité</td>
                      <td>
                        {{
                          $formatPrice(
                            selectedLots[0].subscription.monthly_amount
                          )
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td>Prochain paiement</td>
                      <td>
                        {{
                          new Date(
                            selectedLots[0].subscription.next_due_date
                          ).toLocaleString()
                        }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </table>
              <router-link
                v-if="selectedLots[0].subscription"
                :to="{
                  name: 'subscriptions.details',
                  params: { id: selectedLots[0].subscription.id },
                }"
                class="d-block btn btn-primary mt-3"
              >
                Afficher plus de détails
              </router-link>
            </div>
          </div>
          <div v-else>
            <div>
              <h3>Informations sur le lot</h3>
              <table class="table">
                <tbody>
                  <tr>
                    <td>Propriétaire</td>
                    <td>{{ selectedLots[0].owner || "Aucun propriétaire" }}</td>
                  </tr>
                  <tr>
                    <td>Superficie</td>
                    <td>{{ selectedLots[0].area }}m<sup>2</sup></td>
                  </tr>
                  <!-- <tr>
                    <td>Statut</td>
                    <td>{{ selectedLots[0].status }}</td>
                  </tr> -->
                  <tr>
                    <td>Affecter à un utilisateur</td>
                    <td>
                      <Multiselect
                        v-model="selectedUser"
                        :options="listUsers"
                        class="form-control"
                        :close-on-select="true"
                        :searchable="true"
                        placeholder="Sélectionner un utilisateur"
                      />
                      <button
                        v-if="selectedUser"
                        @click="confirmAndSave"
                        class="btn btn-primary mt-3"
                      >
                        Enregistrer
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
      <!-- Our vision -->
    </div>
  </div>
</template>

<style scoped>
.controls {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.controls button {
  display: block;
  margin-bottom: 10px;
  padding: 5px 10px;
  border: none;
  background-color: #3498db;
  color: #fff;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.controls button:hover {
  background-color: #2980b9;
}

/* .canvas-container {
  position: relative;
  width: 800px;
  height: 600px;
} */
</style>

<style scoped>
table thead th {
  height: 40px;
  padding-left: 20px;
}

table tbody td {
  height: 50px;
  padding-left: 20px;
}
</style>
