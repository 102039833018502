<script>
import { MoreHorizontalIcon } from "@zhuowenli/vue-feather-icons";
// import Multiselect from "@vueform/multiselect";
// import "@vueform/multiselect/themes/default.css";

import FieldsServices from "../../../services/FieldsServices";
import Swal from "sweetalert2";

export default {
  props: ["currentField"],
  data() {
    return {
      currentVisitConfig: null,
      fields: {
        time_slots: [[]],
        trip_modes: [{ mode_fr: "", mode_en: "", price: 0 }],
      },
      isCreateModalOpen: false,
      isUpdateModalOpen: false,
    };
  },
  components: {
    // Multiselect,
    MoreHorizontalIcon,
  },
  emits: ["getFieldVisits"],
  methods: {
    handleOpenCreate() {
      this.isCreateModalOpen = true;
      this.fields = {
        time_slots: [[]],
        trip_modes: [{ mode_fr: "", mode_en: "", price: 0 }],
      };
    },
    handleOpenUpdate(pricing) {
      this.isUpdateModalOpen = true;
      this.currentVisitConfig = pricing;
      this.fields.time_slots = pricing.time_slots
      this.fields.trip_modes = pricing.trip_modes
    },
    async handleCreateFieldVisit() {
      FieldsServices.createVisitConfig(this.$route.params.id, {
        field_id: this.$route.params.id,
        ...this.fields,
      }).then((res) => {
        console.log(res);
        if (!res.error) {
          Swal.fire({
            title: "Confirmation",
            text: "Visite créée avec succès",
            icon: "success",
          });

          this.fields = {
            time_slots: [[]],
            trip_modes: [{ mode_fr: "", mode_en: "", price: 0 }],
          };
        }

        this.$emit("getFieldVisits");
      });
    },
    async handleUpdatePricing() {
      FieldsServices.updateVisitConfig(
        this.currentVisitConfig.id,
        this.$route.params.id,
        {
          ...this.fields,
        }
      ).then((res) => {
        console.log(res);
        if (!res.error) {
          Swal.fire({
            title: "Confirmation",
            text: "Visite mise à jour avec succès",
            icon: "success",
          });
        }

        this.$emit("getFieldVisits");
      });
    },
    addTimeSlot() {
      this.fields.time_slots.push(["", ""]);
    },
    removeTimeSlot(index) {
      this.fields.time_slots.splice(index, 1);
    },
    addTripMode() {
      this.fields.trip_modes.push({ mode_fr: "", mode_en: "", price: 0 });
    },
    removeTripMode(index) {
      this.fields.trip_modes.splice(index, 1);
    },
  },
  mounted() {},
};
</script>

<template>
  <b-modal
    v-model="isUpdateModalOpen"
    hide-footer
    title="Modifier la configuration"
    class="v-modal-custom"
    header-class="bg-light p-3"
  >
    <form action="#" id="addform">
      <div class="mb-3">
        <label class="form-label">Plages horaires</label>
        <div
          v-for="(slot, index) in fields.time_slots"
          :key="index"
          class="d-flex mb-2"
        >
          <input
            type="time"
            class="form-control me-2"
            v-model="fields.time_slots[index][0]"
            required
          />
          <input
            type="time"
            class="form-control me-2"
            v-model="fields.time_slots[index][1]"
            required
          />
          <button
            type="button"
            class="btn btn-danger"
            @click="removeTimeSlot(index)"
          >
            Supprimer
          </button>
        </div>
        <button type="button" class="btn btn-primary" @click="addTimeSlot">
          Ajouter une plage horaire
        </button>
      </div>

      <div class="mb-3">
        <label class="form-label">Modes de transport</label>
        <div
          v-for="(mode, index) in fields.trip_modes"
          :key="index"
          class="d-flex mb-2"
        >
          <input
            type="text"
            class="form-control me-2"
            placeholder="Mode (FR)"
            v-model="fields.trip_modes[index].mode_fr"
            required
          />
          <input
            type="text"
            class="form-control me-2"
            placeholder="Mode (EN)"
            v-model="fields.trip_modes[index].mode_en"
            required
          />
          <input
            type="number"
            class="form-control me-2"
            placeholder="Prix"
            v-model="fields.trip_modes[index].price"
            required
          />
          <button
            type="button"
            class="btn btn-danger"
            @click="removeTripMode(index)"
          >
            Supprimer
          </button>
        </div>
        <button type="button" class="btn btn-primary" @click="addTripMode">
          Ajouter un mode de transport
        </button>
      </div>

      <div class="modal-footer v-modal-footer">
        <div class="hstack gap-2 justify-content-end">
          <button
            type="button"
            id="closemodal"
            class="btn btn-light"
            data-bs-dismiss="modal"
          >
            Fermer
          </button>
          <button
            type="button"
            class="btn btn-success"
            @click="handleUpdatePricing"
          >
            Enregistrer la configuration
          </button>
        </div>
      </div>
    </form>
  </b-modal>

  <b-modal
    v-model="isCreateModalOpen"
    hide-footer
    title="Ajouter une configuration"
    class="v-modal-custom"
    header-class="bg-light p-3"
  >
    <form action="#" id="addform">
      <div class="mb-3">
        <label class="form-label">Plages horaires</label>
        <div
          v-for="(slot, index) in fields.time_slots"
          :key="index"
          class="d-flex mb-2"
        >
          <input
            type="time"
            class="form-control me-2"
            v-model="fields.time_slots[index][0]"
            required
          />
          <input
            type="time"
            class="form-control me-2"
            v-model="fields.time_slots[index][1]"
            required
          />
          <button
            type="button"
            class="btn btn-danger"
            @click="removeTimeSlot(index)"
          >
            Supprimer
          </button>
        </div>
        <button type="button" class="btn btn-primary" @click="addTimeSlot">
          Ajouter une plage horaire
        </button>
      </div>

      <div class="mb-3">
        <label class="form-label">Modes de transport</label>
        <div
          v-for="(mode, index) in fields.trip_modes"
          :key="index"
          class="d-flex mb-2"
        >
          <input
            type="text"
            class="form-control me-2"
            placeholder="Mode (FR)"
            v-model="fields.trip_modes[index].mode_fr"
            required
          />
          <input
            type="text"
            class="form-control me-2"
            placeholder="Mode (EN)"
            v-model="fields.trip_modes[index].mode_en"
            required
          />
          <input
            type="number"
            class="form-control me-2"
            placeholder="Prix"
            v-model="fields.trip_modes[index].price"
            required
          />
          <button
            type="button"
            class="btn btn-danger"
            @click="removeTripMode(index)"
          >
            Supprimer
          </button>
        </div>
        <button type="button" class="btn btn-primary" @click="addTripMode">
          Ajouter un mode de transport
        </button>
      </div>

      <div class="modal-footer v-modal-footer">
        <div class="hstack gap-2 justify-content-end">
          <button
            type="button"
            id="closemodal"
            class="btn btn-light"
            data-bs-dismiss="modal"
          >
            Fermer
          </button>
          <button
            type="button"
            class="btn btn-success"
            @click="handleCreateFieldVisit"
          >
            Enregistrer la configuration
          </button>
        </div>
      </div>
    </form>
  </b-modal>

  <div class="row g-4 mb-3">
    <div class="col-sm-auto">
      <button class="btn btn-success" @click="handleOpenCreate">
        <i class="ri-add-line align-bottom me-1"></i> Nouvelle configuration
      </button>
    </div>
    <div class="col-sm">
      <div class="d-flex justify-content-sm-end gap-2">
        <div class="search-box ms-2">
          <input type="text" class="form-control" placeholder="Rechercher..." />
          <i class="ri-search-line search-icon"></i>
        </div>

        <!-- <Multiselect
            class="multiselect form-control w-lg w-auto m-0"
            v-model="value"
            :close-on-select="true"
            :searchable="true"
            :create-option="true"
            :options="[
              { value: 'Status', label: 'Status' },
              { value: 'Active', label: 'Active' },
              { value: 'Block', label: 'Block' },
            ]"
          /> -->
      </div>
    </div>
  </div>

  <div class="row">
    <div
      class="col-xxl-4 col-sm-6 project-card"
      style="border: 2px solid gray; border-radius: 12px"
      v-for="(item, index) of currentField.visit_configs"
      :key="index"
    >
      <div class="card card-height-100">
        <div class="card-body">
          <div class="d-flex flex-column h-100">
            <div class="d-flex">
              <div class="flex-grow-1">
                <p class="mb-4">Configuration N°{{ index + 1 }}</p>
              </div>
              <div class="flex-shrink-0">
                <div class="d-flex gap-1 align-items-center">
                  <div class="dropdown">
                    <button
                      class="btn btn-link text-muted p-1 mt-n2 py-0 text-decoration-none fs-15 shadow-none"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="true"
                    >
                      <MoreHorizontalIcon class="icon-sm"></MoreHorizontalIcon>
                    </button>

                    <div class="dropdown-menu dropdown-menu-end">
                      <router-link
                        class="dropdown-item"
                        :to="{
                          name: 'field-visits.details',
                          params: { id: item.id },
                        }"
                        ><i
                          class="ri-eye-fill align-bottom me-2 text-muted"
                        ></i>
                        Voir les inscriptions
                      </router-link>
                      <a
                        class="dropdown-item"
                        href="javascript:void(0)"
                        @click="handleOpenUpdate(item)"
                      >
                        <i
                          class="ri-pencil-fill align-bottom me-2 text-muted"
                        ></i>
                        Modifier
                      </a>
                      <div class="dropdown-divider"></div>
                      <!-- <a
                        class="dropdown-item"
                        href="javascript:void(0)"
                        @click.prevent="handleDeleteFieldVisit(item)"
                      >
                        <i
                          class="ri-delete-bin-fill align-bottom me-2 text-muted"
                        ></i>
                        Supprimer
                      </a> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="d-flex" style="gap: 12px">
              <div
                v-for="(tripMode, index) in item.trip_modes"
                :key="index"
                class="mb-2"
                style="
                  border: 2px solid gray;
                  border-radius: 12px;
                  padding: 5px 10px;
                "
              >
                <div class="fw-bold">{{ tripMode.mode_fr }}</div>
                <div class="text-success">
                  {{ $formatPrice(tripMode.price) }}
                </div>
              </div>
            </div>

            <h5 class="mt-2">Plages horaires</h5>
            <div class="d-flex" style="gap: 8px">
              <div
                v-for="(timeSlot, index) in item.time_slots"
                :key="index"
                class="mb-2"
                style="
                  border: 1px solid blue;
                  padding: 4px 8px;
                  border-radius: 8px;
                "
              >
                <div class="">{{ timeSlot[0] }} - {{ timeSlot[1] }}</div>
              </div>
            </div>

            <!-- <div class="mt-auto">
              <div class="d-flex mb-2">
                <div class="flex-grow-1">
                  <div>Souscriptions</div>
                </div>
                <div class="flex-shrink-0">
                  <div>
                    <i class="ri-list-check align-bottom me-1 text-muted"></i>
                    {{ String(item.prospects.length).padStart(2, "0") }}
                  </div>
                </div>
              </div>
            </div> -->
          </div>
        </div>
        <!-- end card body -->
        <div class="card-footer bg-transparent border-top-dashed py-2">
          <div class="d-flex align-items-center">
            <div class="flex-shrink-0">
              <div class="text-muted">
                <i class="ri-calendar-event-fill me-1 align-bottom"></i>
                Ajouté le {{ new Date(item.created_at).toLocaleString() }}
              </div>
            </div>
          </div>
        </div>
        <!-- end card footer -->
      </div>
      <!-- end card -->
    </div>
    <!-- end col -->
  </div>
  <!-- end row -->

  <!-- <div class="row g-0 text-center text-sm-start align-items-center mb-4">
      <div class="col-sm-6">
        <div>
          <p class="mb-sm-0 text-muted">
            Showing <span class="fw-semibold">1</span> to
            <span class="fw-semibold">10</span> of
            <span class="fw-semibold text-decoration-underline">12</span>
            entries
          </p>
        </div>
      </div>
      <div class="col-sm-6">
        <ul
          class="pagination pagination-separated justify-content-center justify-content-sm-end mb-sm-0"
        >
          <li class="page-item disabled">
            <a href="#" class="page-link">Previous</a>
          </li>
          <li class="page-item active">
            <a href="#" class="page-link">1</a>
          </li>
          <li class="page-item">
            <a href="#" class="page-link">2</a>
          </li>
          <li class="page-item">
            <a href="#" class="page-link">3</a>
          </li>
          <li class="page-item">
            <a href="#" class="page-link">4</a>
          </li>
          <li class="page-item">
            <a href="#" class="page-link">5</a>
          </li>
          <li class="page-item">
            <a href="#" class="page-link">Next</a>
          </li>
        </ul>
      </div>
    </div> -->
  <!-- end row -->
</template>
